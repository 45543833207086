html,
body {
  margin: 0;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 600;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  cursor: pointer;
}

/*
  Remove default focus styles for mouse users ONLY if
  :focus-visible is supported on this platform.
*/
button:focus:not(:focus-visible) {
  outline: none;
  box-shadow: 0 0 0 1px transparent;
}

button:disabled {
  cursor: inherit;
}

/* Force scrollbars on table to always show, even in MacOs */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #c4c4c4;
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
